import {cva} from 'class-variance-authority';

import {useTranslations} from '@/hooks/useTranslations';
import {twMerge} from '@/stylesheets/twMerge';
import Grid, {Col} from '@/components/base/layouts/utils/Grid/Grid';
import {getImagerySrc} from '@/components/base/elements/Image/Image';

import TestimonialText from './components/TestimonialText';
import type {TestimonialProps} from './types';

const styles = cva('gap-y-xl', {
  variants: {
    mode: {
      light: 'text-black',
      dark: 'text-white',
    },
    hasImage: {
      true: 'max-md:grid-rows-[min-content_auto]',
    },
  },
});

/**
 * A single testimonial, with no carousel overhead
 */
export default function Testimonial({
  className,
  isFullSpan = false,
  isActive,
  ariaHidden,
  ariaLabel,
  mode = 'light',
  author,
  authorTitle,
  brand,
  image,
  link,
  quoteHtml,
  size,
  style,
}: TestimonialProps) {
  const {t} = useTranslations();
  const componentName = 'testimonial';

  const colProps = [
    {
      start: {
        xs: 1,
        md: 8,
      },
      span: {
        xs: 4,
        sm: 6,
        md: 4,
      },
      className: 'order-1 md:order-2',
    },
    {
      start: 1,
      span: image ? {xs: 4, sm: 8, md: 6} : {xs: 4, sm: 8, md: 12, lg: 9},
      className: 'relative flex flex-col ml-3 md:ml-0 order-2 md:order-1 z-10',
    },
  ];

  return (
    <Grid
      className={twMerge(
        styles({mode, hasImage: image !== undefined}),
        className,
      )}
      data-component-name={componentName}
      aria-hidden={ariaHidden}
      aria-label={ariaLabel || t('global:ariaLabels.testimonial.plural')}
      style={style}
      role="group"
      aria-roledescription={t('global:ariaLabels.carousel.slide')}
    >
      {/* Column One: Image Carousel */}
      {image && (
        <Col {...colProps[0]}>
          {/**
           * Temporary one-off picture implementation pending potential new
           * `<Picture />` component to address `className` not being set on the
           * `<picture>` element in base `<Image>`
           */}
          <picture
            className={twMerge(
              'flex justify-center aspect-square snap-center md:m-auto',
              image.pictureClassName,
            )}
          >
            <img
              alt={image.alt}
              className={twMerge('rounded-lg object-contain', image.className)}
              decoding={image.decoding}
              height={image.height}
              loading="eager"
              src={getImagerySrc(image.src, {
                width: image.width,
                height: image.height,
                crop: image.crop,
              })}
              srcSet={image.srcSet}
              width={image.width}
            />
          </picture>
        </Col>
      )}
      {/* Column Two: Testimonial Text, Byline, and Controls */}
      <Col {...colProps[1]} {...(isFullSpan ? {span: 12} : {})}>
        <TestimonialText
          quoteHtml={quoteHtml}
          author={author}
          authorTitle={authorTitle}
          brand={brand}
          link={link}
          isActive={isActive}
          ariaHidden={ariaHidden}
          mode={mode}
          size={size}
        />
      </Col>
    </Grid>
  );
}
